<template>
  <select class="form-control" :class="{ 'is-invalid': hasError }" :id="inputId" :aria-describedby="helpTextId" :value="value" @input="handleChange">
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
  </select>
</template>

<script>
import inputMixin from './inputMixin'

export default {
  mixins: [
    inputMixin
  ],
  props: {
    options: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>
